<template>
	<TheHeader></TheHeader>
	<div id="container" class="container container--fit">
		<div id="main" class="main">
			<!-- introduce -->
			<section class="introduce">
				<div class="introduce__image noneimage">
					<div class="imagebg" :style="{
						'background-image': 'url(' + require('@/assets/images/img_welcome2.jpg') + ')',
						'background-size': '100% 100%'
					}">
					</div>
				</div>
				<div class="introduce__description">
					<!-- [REST] 닉네임 호출, 문구 변경 가능 -->
					<!-- [D] 로그인 후 표시 -->
					<h2 class="introduce__title">
						<b>지연</b>님, 프라이빗하게 <br>
						힐링하고 싶다면
					</h2>
					<!-- // -->
				</div>
			</section>
			<!-- main search -->
			<section class="search">
				<div class="box">
					<div class="search__wrap">
						<!-- [D] 선택된 후 -->
						<ul class="search__list">
							<!-- [D] 선택 했을시 search__ipt에 active 클래스 추가 -->
							<li>
								<div class="search__inner">
									<i class="icon icon-location">숙소 위치</i>
									<input type="text" class="search__ipt active" readonly value="강남/역삼/삼성">
									<i class="icon icon-right--light"></i>
								</div>
							</li>
							<!-- // -->
							<!-- [D] 선택 했을시 search__ipt에 active 클래스 추가 -->
							<li>
								<div class="search__inner">
									<i class="icon icon-date">숙박 일자</i>
									<input type="text" class="search__ipt active" readonly value="11.11(목) ~ 11.12(금)">
									<i class="icon icon-right--light"></i>
								</div>
							</li>
							<!-- // -->
							<!-- [D] 선택 했을시 li에 active 클래스 추가 -->
							<li class="active">
								<div class="search__inner">
									<i class="icon icon-member">숙박 인원</i>
									<div class="search__resultmember">
										<span class="search__text">성인 2</span>
										<span class="search__dot"></span>
										<span class="search__text">아동 0</span>
									</div>
									<div class="search__ismember">
										<input type="text" class="search__ipt active" readonly placeholder="몇명이서 떠나시나요?">
									</div>
									<i class="icon icon-right--light"></i>
								</div>
							</li>
						</ul>
						<!-- // -->
						<div class="search__submit">
							<button type="button" class="btn-mysearch">
								나만의 숙소찾기
								<i class="icon icon-rightbig">화살표</i>
							</button>
						</div>
					</div>
				</div>
			</section>
			<!-- weather -->
			<section class="weather">
				<div class="box">
					<div class="weather__wrap">
						<!-- [D] 지역 선택 후 -->
						<h2 class="weather__title active">
							<i class="icon icon-compass"></i>
							<span>서울</span>
							<i class="icon icon-down--light"></i>
						</h2>
						<!--[D] 활성화시 active 클래스 추가 -->
						<div class="weather__locations">
							<button class="weather__button active" type="button">서울·경기</button>
							<button class="weather__button" type="button">강원</button>
							<button class="weather__button" type="button">충남</button>
							<button class="weather__button" type="button">충북</button>
							<button class="weather__button" type="button">전남</button>
							<button class="weather__button" type="button">전북</button>
							<button class="weather__button" type="button">경남</button>
							<button class="weather__button" type="button">경북</button>
							<button class="weather__button" type="button">제주</button>
						</div>
						<!-- // -->
						<ul class="weather__list">
							<!-- [REST] 날씨 정보 호출, 클래스별 날씨 .icon-weather--숫자 -->
							<!--
							1 : 맑음
							2 : 구름많음
							3 : 흐림
							4 : 비 (소나기, 흐리고 비, 흐리고 소나기, 구름많고 비, 구름많고 소나기)
							5 :  눈 (구름많고 눈, 흐리고 눈)
							6 : 비/눈 (구름많고 비/눈, 흐리고 비/눈)
							-->
							<li>
								<small>월</small>
								<i class="icon icon-weather icon-weather--1">맑음</i>
								<span>12/01</span>
							</li>
							<li>
								<small>화</small>
								<i class="icon icon-weather icon-weather--2">구름많음</i>
								<span>12/02</span>
							</li>
							<li>
								<small>수</small>
								<i class="icon icon-weather icon-weather--3">흐림</i>
								<span>12/03</span>
							</li>
							<li>
								<small>목</small>
								<i class="icon icon-weather icon-weather--4">비</i>
								<span>12/04</span>
							</li>
							<li>
								<small>금</small>
								<i class="icon icon-weather icon-weather--5">눈</i>
								<span>12/05</span>
							</li>
							<li>
								<small>토</small>
								<i class="icon icon-weather icon-weather--6">눈/비</i>
								<span>12/06</span>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="item">
					<h2 class="title">
						지금 인기 있는 숙박
					</h2>
					<div class="item__body">
						<div class="carousel">
							<!-- [REST] 현재 기준 가까운 상품 10개 호출 -->
							<div class="carousel__slider">
								<swiper :slides-per-view="1.4" :space-between="16">
									<swiper-slide>
										<router-link to="/rooms/list" class="carousel__link">
											<div class="carousel__image noneimage">
												<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/tempsquare01.jpg') + ')'}">
												</div>
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													환상적인 루프탑이 있어요
												</div>
												<h3 class="carousel__title info-title">
													역삼 벤
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														5.0
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__day">
														1박기준
													</span>
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</router-link>
									</swiper-slide>
									<swiper-slide>
										<a href="#" class="carousel__link">
											<div class="carousel__image noneimage">
												<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/tempsquare01.jpg') + ')'}">
												</div>
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													환상적인 루프탑이 있어요
												</div>
												<h3 class="carousel__title info-title">
													역삼 벤
												</h3>
												<div class="carousel__grad info-grad">
													<span class="carousel__icon info-icon">
														<i class="icon icon-star"></i>
													</span>
													<span class="carousel__gradnum info-gradnum">
														5.0
													</span>
													<span class="carousel__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
												<div class="carousel__price">
													<span class="carousel__day">
														1박기준
													</span>
													<span class="carousel__saleprice">
														28%
													</span>
													<span class="carousel__currentprice">
														<strong>49,800</strong>원
													</span>
												</div>
											</div>
										</a>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<!-- [REST] 회원 닉네임 호출, 메시니 내용 수정 가능 -->
							<a href="#" class="card__link">
								<div class="card__inner">
									<h2 class="card__title">
										<strong>떠나요 #부산</strong>
									</h2>
									<p class="card__description">
										모든걸 훌훌 버리고
									</p>
									<div class="card__icon" :style="{'background-image': 'url(' + require('@/assets/images/tempsquare01.jpg') + ')'}">
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<!-- [REST] 이후 카드형태 모두 피드 컨텐츠 호출 -->
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp04.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									지금은 차박시대
								</h2>
								<p class="card__description">
									차박 초보자를 위한 가이드
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp15.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									평창 카페 바셀로
								</h2>
								<p class="card__description">
									감성 카페 투어! 분위기 맛집
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link">
								<div class="card__image">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp03.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									밤의 낭만을 그대에게
								</h2>
								<p class="card__description">
									나만 아는 동네 / 충주
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card bg">
						<div class="card__badge badge badge--notice">
							<span class="badge__text">
								이벤트
							</span>
						</div>
						<!-- [REST] 이벤트 최신순으로 호출 -->
						<div class="card__slider">
							<!-- [D] scrollbar의 el 항목으로 슬라이더 다음에 있는 progress__bar의 클래스 이름 지정-->
							<swiper :scrollbar="{ el : '.progress__bar--1' }">
								<swiper-slide>
									<a href="#" class="card__link">
										<div class="card__image noneimage">
											<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp-event01.jpg') + ')'}"></div>
										</div>
									</a>
								</swiper-slide>
								<swiper-slide>
									<a href="#" class="card__link">
										<div class="card__image noneimage">
											<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp-event01.jpg') + ')'}"></div>
										</div>
									</a>
								</swiper-slide>
							</swiper>
							<div class="progress">
								<div class="progress__bar progress__bar--1"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link card__link--share">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp07.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									가을날의 물놀이
								</h2>
								<p class="card__description">
									국내 감성 풀빌라 베스트3
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link card__link--share">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp08.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									나랑 별 보러 가지 않을래?
								</h2>
								<p class="card__description">
									별 보기 좋은 전국의 천문대
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link card__link--share">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp09.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									제주에 뜬 미식의 별
								</h2>
								<p class="card__description">
									제주도 스타 셰프의 맛집
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link">
								<div class="card__inner">
									<h2 class="card__title">
										추워도 너무 춥다!
									</h2>
									<p class="card__description">
										넷플릭스 보면서 뒹굴거리기 어때요?
									</p>
									<div class="card__icon">
										<i class="icon icon-mylock"></i>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link card__link--share">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp10.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									역사덕후들을 위한 역사투어
								</h2>
								<p class="card__description">
									밀양 유적지 월연정
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link card__link--share">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp11.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									군산으로 시간 여행을 떠나자!
								</h2>
								<p class="card__description">
									신비로운 군산시간여행마을
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<div class="card__wrap">
							<a href="#" class="card__link card__link--share">
								<div class="card__image noneimage">
									<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp12.jpg') + ')'}"></div>
								</div>
								<h2 class="card__title">
									춘천 명물 감자빵
								</h2>
								<p class="card__description">
									한번만 먹어본 사람은 없다!
								</p>
							</a>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="card">
						<!-- [D] 뱃지 있을 시 추가 -->
						<div class="card__badge badge badge--plan">
							<span class="badge__text">
								기획전
							</span>
						</div>
						<!-- [REST] 기획전 최신순으로 호출 -->
						<div class="card__slider">
							<!-- [D] scrollbar의 el 항목으로 슬라이더 다음에 있는 progress__bar의 클래스 이름 지정-->
							<swiper :scrollbar="{ el : '.progress__bar--2' }">
								<swiper-slide>
									<a href="#" class="card__link">
										<div class="card__image noneimage">
											<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp-event02.jpg') + ')'}"></div>
										</div>
									</a>
								</swiper-slide>
								<swiper-slide>
									<a href="#" class="card__link">
										<div class="card__image noneimage">
											<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp-event02.jpg') + ')'}"></div>
										</div>
									</a>
								</swiper-slide>
							</swiper>
							<div class="progress">
								<div class="progress__bar progress__bar--2"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<!-- [D] 메인 로딩 완료시 hide 클래스 추가 -->
	<div class="skeleton hide">
		<div class="skeleton__bg"></div>
	</div>
	<TheFooter></TheFooter>
	<TheNavigation></TheNavigation>
</template>